import React from "react";
import "./style.css";

//sections
import SideNavContent from "../../sections/SideNavContent";
import Header from "../../sections/CenterHeader";

//components
import DropdownList from "../../comps/DropdownList";

//content
const sidenavLinks = [
  { title: "Community", link: "https://www.reddit.com/r/Flipit_ai" },
  { title: "Examples", link: "" },
  { title: "Contact us", link: "" },
];
const pageContent = [
  {
    section: "Welcome",
    comp: DropdownList,
    content: [
      {
        title: "What is flipit?",
        content: (
          <div>
            <p>
              Flipit is a robust, private web API that has everything you need
              to take your real estate application from prototype to production.
              Flipit uses machine learning to extract data from real estate
              related imagery.
            </p>
            <ul>
              <li> Label your examples</li>
              <li> Train a model</li>
              <li> Evaluate training Results</li>
              <li> Play with your model</li>
              <li> Export and use your model in an app</li>
            </ul>
            <br />
            <a href="/">see more</a>
          </div>
        ),
      },
      {
        title: "What is Machine Learning?",
        content: (
          <div>
            <p>
              Machine learning is software that learns to perform a task from a
              collection of examples rather than through a person explicitly
              defining rules and formulas. This learning software is called a
              model. Teaching a model through examples is called training.
            </p>

            <p>
              Learn more in this beginner{" "}
              <a
                href="https://www.microsoft.com/en-us/ai/ai-school"
                target="blank"
              >
                crash course
              </a>
              .
            </p>
          </div>
        ),
      },
      {
        title: "What kind of machine learning does Flipit use?",
        content: (
          <p>
            This version of Flipit learns to look at images using image
            classification - categorizing an image into a single label overall.
            We are working to expand to more types of problems and data in
            future versions.
          </p>
        ),
      },
    ],
  },
  {
    section: "Getting Started",
    comp: DropdownList,
    content: [
      {
        title: "Where do I start?",
        content: "filler Text",
      },
    ],
  },
  {
    section: "API",
    comp: DropdownList,
    content: [
      {
        title: "Integration?",
        content: "filler Text",
      },
    ],
  },
  {
    section: "Improve",
    comp: DropdownList,
    content: [
      {
        title: "Can I improve the modlel?",
        content: "filler Text",
      },
    ],
  },
];

const Docs = (props) => {
  return (
    <div className="docs-wrao">
      <Header
        title={
          <span>
            Flipit{" "}
            <span style={{ color: "var(--selected-font-color)" }}>Docs</span>
          </span>
        }
        subTitle="Everything you need to know to integrate the flipit API into your application."
      />
      <SideNavContent
        root={"docs"}
        staticLinks={sidenavLinks}
        pageContent={pageContent}
      />
    </div>
  );
};

export default Docs;
