import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import "./style.css";

//components
import Sidebar from "../../comps/Sidebar";

const Landing = (props) => {
  const fallBackContent = [
    {
      section: "Section",
      content: [
        {
          title: "title",
          content: "content",
        },
      ],
    },
  ];
  const pageContent = props.pageContent ? props.pageContent : fallBackContent;
  const firstItem = props.pageContent[0];
  const user = props.user;

  return (
    <section className=" flex-align-cent flex-col">
      <div className="global-content-wrapper docs-wrapper flex-align-top">
        <Sidebar
          root={props.root}
          sections={pageContent}
          staticLinks={props.staticLinks}
        />
        <Switch>
          {props.pageContent.map((page, i) => {
            let route = `/${props.root}/${page.section
              .toLowerCase()
              .replace(/\s/g, "-")}`;
            let Componennt = page.comp;
            return (
              <Route
                key={route + "-key"}
                path={route}
                render={(props) => (
                  <Componennt {...props} user={user} content={page.content} />
                )}
              />
            );
          })}
          <Route
            render={(props) => {
              let Componennt = firstItem.comp;
              return (
                <Componennt
                  {...props}
                  user={user}
                  content={firstItem.content}
                />
              );
            }}
          />
        </Switch>
      </div>
    </section>
  );
};

export default Landing;
