import React, { useState, useEffect, useRef } from "react";

import "./style.css";

//compoents
import Spinner from "../Spinner";

//images
import LandingImg from "../../media/images/landing.jpg";

const Comp = (props) => {
  return (
    <div style={{ maxWidth: "100%" }}>
      <ol>
        <li>data = {`{`}</li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          pov: <span style={{ color: "#ffa956" }}>'interior'</span>,
        </li>

        <li>
          <span style={{ paddingLeft: "16px" }} />
          finished: <span style={{ color: "#8095ff" }}>true</span>,
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          furnished: {`{`}
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          interiro_design:{" "}
          <span style={{ color: "#ffa956" }}>'Contemporary'</span>,
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          use_case: <span style={{ color: "#ffa956" }}>'livingroom'</span>,
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          {`}`},
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          floor_plan: <span style={{ color: "#ffa956" }}>'open'</span>,
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} /> room_type: {`{`}
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          type: <span style={{ color: "#ffa956" }}>'living room'</span>,
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} /> materials: {`{`}
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          flooring: <span style={{ color: "#ffa956" }}>'wood'</span>,
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} /> walls: {`{`}
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          color: <span style={{ color: "#ffa956" }}>'white'</span>,
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          material: <span style={{ color: "#ffa956" }}>'unknown'</span>,
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} /> {`}`},
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} /> ceiling: 'wood',
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} /> {`}`},
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} /> attributes: {`{`}
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} /> windows: {`{`}
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          count: <span style={{ color: "#ffa956" }}>2</span>,
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          style: <span style={{ color: "#ffa956" }}>'picture windows'</span>,
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} /> {`}`},
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          fireplace: {`{`}
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          material: <span style={{ color: "#ffa956" }}>'stone'</span>,
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} /> {`}`},
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} />
          objects: [<span style={{ color: "#ffa956" }}>'couch'</span>,{" "}
          <span style={{ color: "#ffa956" }}>'chair'</span>,
          <span style={{ color: "#ffa956" }}>'rug'</span>,
          <span style={{ color: "#ffa956" }}>'table'</span>],
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          <span style={{ paddingLeft: "16px" }} /> {`}`},
        </li>
        <li>
          <span style={{ paddingLeft: "16px" }} />
          {`}`},
        </li>
        <li>{`};`}</li>
      </ol>
    </div>
  );
};

export default Comp;

// <div className='code-mobile-wrapper'>
//   <span className='code-sec-title'>
//     Payload <br />
//     <span style={{ color: '#ffa956' }}>'IMG_URL'</span> ={'  '}
//     <span style={{ color: '#ffa956', textTransform: 'none' }}>
//       'https://www.wesbite/image.jpg'
//     </span>
//   </span>
// </div>
