import React from "react";
import "./style.css";

import Button from "../../Button";

const Auth = (props) => {
  return (
    <div
      className={`flex-align-cent flex-just-cent flex-col card-wrapper ${
        props.content && props.content.selected ? "selected-card-wrapper" : null
      }`}
    >
      {props.content && props.content.image ? (
        <div className="card-media-wrapper"></div>
      ) : null}

      <div className="card-text-wrapper">
        <h5>{props.content ? props.content.title : "(No Title)"}</h5>
        {props.content && props.content.price ? (
          props.content.price !== "Custom" ? (
            <div className="card-price-wrapper flex-align-cent flex-just-cent">
              <h6>$</h6>
              <h3>{props.content.price}</h3>{" "}
              <h6 style={{ marginLeft: "8px" }}>unit/month</h6>
            </div>
          ) : (
            <h3 className="card-price-wrapper">Custom</h3>
          )
        ) : null}
        <p>{props.content ? props.content.text : "(No text)"}</p>
      </div>
      <Button
        className={`${
          props.content && props.content.selected ? "blue-button" : ""
        }`}
      >
        {props.content ? props.content.button : "(No text)"}
      </Button>
    </div>
  );
};

export default Auth;
