import React from "react";

//sections

const Account = (props) => {
  return (
    <div className="section-content section-content--center">
      <h1 className="section-heading">
        Flipit <span className="section-heading--green">Examples</span>
      </h1>
      <p className="section-text">
        One tool, endless possibilities. Discover all the different kinds of
        information you can gather with flipit.
      </p>
    </div>
  );
};

export default Account;
