import React from "react";

//sections
import Header from "../../sections/Examples";
import Cards from "../../sections/ExampleCards";
import FootNote from "../../sections/Prompt";

const Docs = (props) => {
  return (
    <div>
      <section className="section">
        <Header />
        <Cards />
      </section>
    </div>
  );
};

export default Docs;
