import React, { useState } from "react";
import "./style.css";

const Landing = (props) => {
  const user = props.user;

  return (
    <div className="">
      {user ? (
        <div>
          <p>
            <b>name:</b> {user.displayName}
          </p>
          <p>
            <b>email:</b> {user.email}
          </p>
          <p>
            <b>name:</b> {user.displayName}
          </p>
          <p>
            <b>verified:</b> {user.emailVerified}
          </p>
          <p>
            <b>id:</b> {user.uid}
          </p>
        </div>
      ) : (
        "no user detected"
      )}
    </div>
  );
};

export default Landing;
