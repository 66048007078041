import React from "react";

//compoents

//media
import List1sm from "../../media/images/list1sm.png";
import List1lg from "../../media/images/list1lg.png";
import List2sm from "../../media/images/list2sm.png";
import List2lg from "../../media/images/list2lg.png";
import List3sm from "../../media/images/list3sm.png";
import List3lg from "../../media/images/list3lg.png";

const Comp = (props) => {
  const user = props.user;

  return (
    <section className="section ltp-overview">
      <div className="section-content">
        <h2 className="section-heading">
          Post, Process, <span className="section-heading--green">Play</span>
        </h2>
        <p className="section-text">
          Flipit incorporates ML capabilities into your app in three easy steps.
          First, post your images to Flipit. Second, Flipit will process them.
          Third, play with your results and incorporate them into your app.
        </p>
      </div>
      <div className="label-your-images">
        <div className="section-spanning-video">
          <img
            src={List1lg}
            className="ltp-video"
            alt="Quickly label images in Flipit by selecting an image, then typing or selecting a label."
            loading="lazy"
          />

          <video
            className="ltp-video"
            playsInline
            webkit-playsinline="true"
            preload="none"
            title="Quickly label images in Flipit by selecting an image, then typing or selecting a label."
            style={{ visibility: "visible" }}
          ></video>

          <img
            src={List1sm}
            className="ltp-video-mobile"
            alt="Quickly label images in Flipit by selecting an image, then typing or selecting a label."
            loading="lazy"
          />

          <video
            className="ltp-video-mobile"
            playsInline
            webkit-playsinline="true"
            preload="none"
            title="Quickly label images in Flipit by selecting an image, then typing or selecting a label."
            style={{ visibility: "visible" }}
          ></video>
        </div>
        <div className="section-content">
          <div className="ltp-section-content">
            <h2 className="header-2">
              <span className="section-heading--green">Post</span> your images
            </h2>
            <p className="section-text">
              Use a simple POST method to initiate Flipit. The payload may
              contain image files or URLs. Flipit can also handle listing URLs
              from major sites like Redfin, Zillow, or Keller Williams.
            </p>
          </div>
        </div>
      </div>
      <div className="train-your-model">
        <div className="section-spanning-video">
          <img
            src={List2lg}
            className="ltp-video"
            alt="Once you've added and labeled images, Flipit will automatically start training and showing the predicted label for each image."
            loading="lazy"
          />
          <video
            className="ltp-video"
            playsInline
            webkit-playsinline="true"
            preload="none"
            title="Once you've added and labeled images, Flipit will automatically start training and showing the predicted label for each image."
            style={{ visibility: "visible" }}
          ></video>
          <img
            src={List2sm}
            className="ltp-video-mobile"
            alt="Once you've added and labeled images, Flipit will automatically start training and showing the predicted label for each image."
            loading="lazy"
          />
          <video
            className="ltp-video-mobile"
            playsInline
            webkit-playsinline="true"
            preload="none"
            title="Once you've added and labeled images, Flipit will automatically start training and showing the predicted label for each image."
            style={{ visibility: "visible" }}
          ></video>
        </div>
        <div className="section-content">
          <div className="ltp-section-content">
            <h2 className="header-2">
              <span className="section-heading--green">Process </span> the data
            </h2>
            <p className="section-text">
              Flipit will automatically decipher the data. Its ML models will
              pick out materials, floor plans, furnishings, and much more. In
              future builds, Flipit will also be able to recommend upgrades.
            </p>
          </div>
        </div>
      </div>
      <div className="play-with-it">
        <div className="section-spanning-video">
          <img
            src={List3lg}
            className="ltp-video"
            alt="Finally, you can add image to Flipit from your computer, or use your webcam to get predictions in real-time for images your model has never seen. Mark them correct or incorrect to add them to your dataset."
            loading="lazy"
          />
          <video
            className="ltp-video"
            playsInline
            webkit-playsinline="true"
            preload="none"
            title="Finally, you can add image to Flipit from your computer, or use your webcam to get predictions in real-time for images your model has never seen. Mark them correct or incorrect to add them to your dataset."
            style={{ visibility: "visible" }}
          ></video>
          <img
            src={List3sm}
            className="ltp-video-mobile"
            alt="Finally, you can add image to Flipit from your computer, or use your webcam to get predictions in real-time for images your model has never seen. Mark them correct or incorrect to add them to your dataset."
            loading="lazy"
          />
          <video
            className="ltp-video-mobile"
            playsInline
            webkit-playsinline="true"
            preload="none"
            title="Finally, you can add image to Flipit from your computer, or use your webcam to get predictions in real-time for images your model has never seen. Mark them correct or incorrect to add them to your dataset."
            style={{ visibility: "visible" }}
          ></video>
        </div>
        <div className="section-content">
          <div className="ltp-section-content">
            <h2 className="header-2">
              <span className="section-heading--green">Play</span> with it
            </h2>
            <p className="section-text">
              Flipit returns the analysis for each image in JSON format so that
              you may easily integrate it into your application.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Comp;

// <div className="image-tag image-tag-1">
//   <span>Uploading...</span>
//   <div className="loading-bar" />
// </div>
// <div className="image-tag image-tag-2">
//   <span>Uploading...</span>
//   <div className="loading-bar" />
// </div>
// <div className="image-tag image-tag-3">
//   <span>Uploading...</span>
//   <div className="loading-bar" />
// </div>
// <div className="image-tag image-tag-4">
//   <span>Uploading...</span>
//   <div className="loading-bar" />
// </div>
