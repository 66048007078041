import React from "react";

import "./style.css";

import PricingCards from "../../sections/PricingCards";

const Pricing = (props) => {
  return (
    <div className="">
      <PricingCards />
    </div>
  );
};

export default Pricing;
