import React from "react";
import "./style.css";

//sections
import Landing from "../../sections/Landing";
import About from "../../sections/About";
import Use from "../../sections/Use";
import HowTo from "../../sections/HowTo";
import Prompt from "../../sections/Prompt";
// import zillowScrub from "../../data/zillowScrub.json";

const Home = (props) => {
  let links = [];

  return (
    <div className="" style={{ overflow: "hidden" }}>
      <Landing />
      <About />
      <HowTo />
      <Use />
    </div>
  );
};

export default Home;
