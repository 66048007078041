import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import "./App.css";

//firebase
import firebase from "./firebase";

// Pages
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Docs from "./pages/Docs";
import Account from "./pages/Account";
import Authentication from "./pages/Authentication";
import LogOut from "./pages/LogOut";
import Examples from "./pages/Examples";
import Help from "./pages/Help";

//Sections
import Header from "./sections/Header";
import Footer from "./sections/Footer";

//components
import Modal from "./comps/Modal";

//axios
import axios from "axios";
axios.defaults.baseURL =
  "https://us-central1-flipit---phase-1.cloudfunctions.net/api";
// "http://localhost:5000/flipit---phase-1/us-central1/api";

function App() {
  const [previousLocation, setPreviousLocation] = useState();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  //authentication
  firebase.auth().onAuthStateChanged(function (firebaseUser) {
    if (firebaseUser) {
      // console.log("USER: ", firebaseUser ? true : false);
      console.log(firebaseUser.getIdToken());
      setUser(firebaseUser);
    } else {
      // console.log("USER: ", firebaseUser ? true : false);
      setUser(null);
    }
  });

  //Modals
  let location = useLocation();
  useEffect(() => {
    if (!(location.state && location.state.modal)) {
      setPreviousLocation(location);
    }
  }, [location]);
  const isModal =
    location.state && location.state.modal && previousLocation !== location
      ? true
      : false;

  //dectect theme
  const SystemSetDarkMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  const userSetDarkMode = JSON.parse(localStorage.getItem("userSetDarkMode"));
  if (userSetDarkMode !== null ? userSetDarkMode : SystemSetDarkMode) {
    document.documentElement.setAttribute("data-theme", "dark");
  }

  return (
    <div className="App">
      <Header user={user} loading={loading} />
      <div style={{ marginTop: "80px" }}>
        <Switch location={isModal ? previousLocation : location}>
          <Route exact path="/" component={Home} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/docs" component={Docs} />
          <Route path="/help" component={Help} />
          <Route path="/examples" component={Examples} />
          <Route
            path="/account"
            render={(props) => <Account {...props} user={user} />}
          />
          <Route
            path="/authentication"
            render={(props) => <Authentication {...props} user={user} />}
          />
          <Route
            path="/logout"
            render={(props) => <LogOut {...props} user={user} />}
          />
        </Switch>
      </div>

      <Footer user={user} />
      {isModal ? (
        <Route
          path="/modal/:id"
          render={(props) => <Modal {...props} user={user} />}
        />
      ) : null}
    </div>
  );
}

export default App;
