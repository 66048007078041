import React from "react";
import "./style.css";

const Profile = (props) => {
  return (
    <div className="image-placeholder-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="46"
        height="62"
        fill="none"
        viewBox="0 0 46 62"
      >
        <g clipPath="url(#clip0)">
          <rect
            className="img-svg-background"
            width="62"
            height="46"
            y="62"
            fill="#1C252E"
            rx="3"
            transform="rotate(-90 0 62)"
          ></rect>
          <rect
            width="54.42"
            height="54.42"
            fill="#F3F4F4"
            rx="5"
            transform="scale(.99458 1.0054) rotate(-45 70.885 70.786)"
          ></rect>
          <rect
            width="54.42"
            height="54.42"
            fill="#DFE1E2"
            rx="5"
            transform="scale(.99458 1.0054) rotate(-45 72.824 34.525)"
          ></rect>
          <ellipse
            cx="18.4"
            cy="13.95"
            fill="#DFE1E2"
            rx="6.133"
            ry="6.2"
          ></ellipse>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="62"
              height="46"
              y="62"
              fill="#fff"
              rx="3"
              transform="rotate(-90 0 62)"
            ></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Profile;
