import React from "react";
import "./style.css";

//components
import Cards from "../../comps/Cards";

const Landing = (props) => {
  return (
    <section className="centered-page-header flex-align-cent flex-col">
      <div className="global-content-wrapper">
        <div
          className="header-text flex-align-cent flex-col"
          style={{ textAlign: "center" }}
        >
          <h1 className="title-cntainers">{props.title}</h1>
          <h2 className="text-cntainers">{props.subTitle}</h2>
        </div>
      </div>
    </section>
  );
};

export default Landing;
