import React, { useEffect, useRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";

import "./style.css";
import Button from "../Button";
import Logo from "../../media/svgs/Logo";

const Menu = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const user = props.user;
  const userSetDarkMode = JSON.parse(localStorage.getItem("userSetDarkMode"));

  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current) {
      setIsVisible(false);
    }
  };

  return (
    <div className="mobile-only">
      <div
        onClick={() => setOpenMenu(!openMenu)}
        className="header-mobile-menu flex-align-cent flex-just-cent"
        style={{ transform: "scaleX(-1)" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          height="24px"
          width="24px"
        >
          <path
            fill="var(--dark-font-color)"
            d="M18.35,6H3.05C2.58,6,2.2,6.45,2.2,7s0.38,1,0.85,1h15.3c0.47,0,0.85-0.45,0.85-1S18.82,6,18.35,6z"
          ></path>
          <path
            fill="var(--dark-font-color)"
            d="M21.2,11h-18c-0.55,0-1,0.45-1,1s0.45,1,1,1h18c0.55,0,1-0.45,1-1S21.76,11,21.2,11z"
          ></path>
          <path
            fill="var(--dark-font-color)"
            d="M13.2,16h-10c-0.5,0-1,0.45-1,1s0.5,1,1,1h10c0.5,0,1-0.45,1-1S13.7,16,13.2,16z"
          ></path>
        </svg>
      </div>
      {openMenu ? (
        <div
          className="mobile-dropdown-menu-wrap"
          onClick={() => setOpenMenu(!openMenu)}
        >
          <nav className="mobile-dropdown-menu" ref={wrapperRef}>
            <div
              className="flex-align-cent flex-just-out"
              style={{
                borderBottom: "1px solid var(--line-color)",
              }}
            >
              <Link to="/" style={{ padding: " 14px 30px 14px 8px" }}>
                <Logo />
              </Link>
              <div className="flex-align-cent flex-just-rit mobile-ations">
                <div
                  style={{ color: userSetDarkMode ? "#34a8fe" : "" }}
                  onClick={() => {
                    if (!userSetDarkMode) {
                      localStorage.setItem("userSetDarkMode", true);
                      document.documentElement.setAttribute(
                        "data-theme",
                        "dark"
                      );
                    } else {
                      localStorage.setItem("userSetDarkMode", false);
                      document.documentElement.setAttribute("data-theme", null);
                    }
                  }}
                >
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M17.75,4.09L15.22,6.03L16.13,9.09L13.5,7.28L10.87,9.09L11.78,6.03L9.25,4.09L12.44,4L13.5,1L14.56,4L17.75,4.09M21.25,11L19.61,12.25L20.2,14.23L18.5,13.06L16.8,14.23L17.39,12.25L15.75,11L17.81,10.95L18.5,9L19.19,10.95L21.25,11M18.97,15.95C19.8,15.87 20.69,17.05 20.16,17.8C19.84,18.25 19.5,18.67 19.08,19.07C15.17,23 8.84,23 4.94,19.07C1.03,15.17 1.03,8.83 4.94,4.93C5.34,4.53 5.76,4.17 6.21,3.85C6.96,3.32 8.14,4.21 8.06,5.04C7.79,7.9 8.75,10.87 10.95,13.06C13.14,15.26 16.1,16.22 18.97,15.95M17.33,17.97C14.5,17.81 11.7,16.64 9.53,14.5C7.36,12.31 6.2,9.5 6.04,6.68C3.23,9.82 3.34,14.64 6.35,17.66C9.37,20.67 14.19,20.78 17.33,17.97Z"
                    />
                  </svg>
                </div>
                <div>
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <NavLink to="/" exact activeClassName="active-navbar-link-mobile">
              Overview
            </NavLink>
            <NavLink to="/Examples" activeClassName="active-navbar-link-mobile">
              Examples
            </NavLink>
            <NavLink to="/Pricing" activeClassName="active-navbar-link-mobile">
              Pricing
            </NavLink>
            <NavLink to="/Docs" activeClassName="active-navbar-link-mobile">
              Docs
            </NavLink>
            <NavLink to="/help" activeClassName="active-navbar-link-mobile">
              Help
            </NavLink>
            {!user ? (
              <Link
                to={{
                  pathname: "/modal/authentication",
                  state: { modal: true },
                }}
              >
                <Button
                  className="blue-button"
                  onClick={() => setOpenMenu(!openMenu)}
                >
                  Sign up
                </Button>
              </Link>
            ) : (
              <div style={{ width: "100%" }} className="flex-col">
                <NavLink
                  to="/account"
                  exact
                  activeClassName="active-navbar-link-mobile"
                >
                  Account
                </NavLink>
                <NavLink
                  to="/logout"
                  exact
                  activeClassName="active-navbar-link-mobile"
                >
                  Logout
                </NavLink>
                <NavLink
                  style={{
                    borderTop: `1px solid var(--line-color)`,
                    marginTop: "16px",
                    paddingTop: "28px",
                  }}
                  to="/upgrade"
                  exact
                  className="active-navbar-link-mobile"
                >
                  Upgrade
                </NavLink>
              </div>
            )}
          </nav>
        </div>
      ) : null}
    </div>
  );
};

export default Menu;
