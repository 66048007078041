import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import SidebarItem from "./SidebarItem";

const Landing = (props) => {
  return (
    <ul className="docs-sidebar-wrapper flex-align-top flex-col">
      {props.sections.map((section, i) => (
        <SidebarItem
          index={i}
          root={props.root}
          key={`docs-sec-${section.section}`}
          section={section}
          active={props.active}
        />
      ))}
      {props.staticLinks ? (
        <div className="docs-helpful-links flex-col desktop-only">
          {props.staticLinks.map((link) => (
            <Link key={`docs-links-${link.title}`} to={link.link}>
              {link.title}
            </Link>
          ))}
        </div>
      ) : null}
    </ul>
  );
};

export default Landing;
