import React from "react";
import "./style.css";

//compoents
import Graphic from "../../comps/Graphic";

const Comp = (props) => {
  return (
    <div className="" id="about-section">
      <section className="section section--made-simple section--dark">
        <div className="section-content">
          <h2 className="section-heading">
            Get More from <br /> your{" "}
            <span className="section-heading--green">listings</span>
          </h2>
          <p className="section-text" style={{ marginTop: "24px" }}>
            Flipit is the ultimate tool for taking your real estate applications
            to the next level. Just point it at any interior or exterior
            imagery, and it automatically returns relevant text based data that
            can be shipped in your app.
          </p>
        </div>
        <Graphic />
        <div className="section-content">
          <div className="features-container">
            <div className="tip-cards tip-cards--features">
              <div className="tip-card">
                <div className="tip-card-header">
                  <div className="tip-card-icon-container">
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M12,2A7,7 0 0,1 19,9C19,11.38 17.81,13.47 16,14.74V17A1,1 0 0,1 15,18H9A1,1 0 0,1 8,17V14.74C6.19,13.47 5,11.38 5,9A7,7 0 0,1 12,2M9,21V20H15V21A1,1 0 0,1 14,22H10A1,1 0 0,1 9,21M12,4A5,5 0 0,0 7,9C7,11.05 8.23,12.81 10,13.58V16H14V13.58C15.77,12.81 17,11.05 17,9A5,5 0 0,0 12,4Z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="tip-card-content">
                  <div className="tip-card-title">Easy to Use</div>
                  <div className="tip-card-text">
                    Add ML functionality in just a few lines of code. Designed
                    for any level developer.
                  </div>
                </div>
              </div>
              <div className="tip-card">
                <div className="tip-card-header">
                  <div className="tip-card-icon-container">
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M15,4A8,8 0 0,1 23,12A8,8 0 0,1 15,20A8,8 0 0,1 7,12A8,8 0 0,1 15,4M15,6A6,6 0 0,0 9,12A6,6 0 0,0 15,18A6,6 0 0,0 21,12A6,6 0 0,0 15,6M14,8H15.5V11.78L17.83,14.11L16.77,15.17L14,12.4V8M2,18A1,1 0 0,1 1,17A1,1 0 0,1 2,16H5.83C6.14,16.71 6.54,17.38 7,18H2M3,13A1,1 0 0,1 2,12A1,1 0 0,1 3,11H5.05L5,12L5.05,13H3M4,8A1,1 0 0,1 3,7A1,1 0 0,1 4,6H7C6.54,6.62 6.14,7.29 5.83,8H4Z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="tip-card-content">
                  <div className="tip-card-title">Lightning fast</div>
                  <div className="tip-card-text">
                    Flipit uses edge-of-network computing to generate results
                    with little to no latency.
                  </div>
                </div>
              </div>
              <div className="tip-card">
                <div className="tip-card-header">
                  <div className="tip-card-icon-container">
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M20,16V4H8V16H20M22,16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H20A2,2 0 0,1 22,4V16M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="tip-card-content">
                  <div className="tip-card-title">Ship Anywhere</div>
                  <div className="tip-card-text">
                    Add complex analysis to any application with a simple HTTP
                    POST method
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="made-simple-models">
            <h3 className="header-2">
              <span className="section-heading--green">Project</span> Roadmap
            </h3>
            <p className="section-text">
              Flipit automatically returns the most relevant data for your
              images. Fundamental analysis is available now, with more insights
              coming soon.
            </p>
          </div>
        </div>
        <div className="section-content model-cards-container">
          <div className="tip-cards tip-cards--white">
            <div className="tip-card tip-card--white">
              <div className="tip-card-header">
                <div className="tip-card-icon-container">
                  <svg
                    className="roadmap-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 232 144"
                  >
                    <rect
                      width="232"
                      height="144"
                      fill="var(--hover-color)"
                      fillOpacity="0.1"
                      rx="24"
                    ></rect>
                    <rect
                      width="80"
                      height="100"
                      x="28"
                      y="22"
                      fill="var(--med-faint-color)"
                      rx="10"
                    ></rect>
                    <rect
                      width="31"
                      height="12"
                      x="37"
                      y="102"
                      fill="var(--selected-font-color)"
                      rx="6"
                    ></rect>
                    <circle
                      cx="67.5"
                      cy="64.5"
                      r="16.5"
                      fill="var(--dark-font-color)"
                    ></circle>
                    <rect
                      width="80"
                      height="100"
                      x="123"
                      y="22"
                      fill="var(--med-faint-color)"
                      rx="10"
                    ></rect>
                    <rect
                      width="31"
                      height="12"
                      x="132"
                      y="102"
                      fill="var(--selected-font-color)"
                      rx="6"
                    ></rect>
                    <path
                      fill="var(--dark-font-color)"
                      d="M160.284 49.614c1.965-3.083 6.467-3.083 8.432 0l13.833 21.698c2.122 3.329-.269 7.688-4.216 7.688h-27.666c-3.947 0-6.338-4.36-4.216-7.688l13.833-21.698z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="tip-card-content">
                <div className="tip-card-title">Fundamental Analysis</div>
                <div className="tip-card-text">
                  Analyze an image based on its content.
                </div>
              </div>
              <div className="tip-card-availability">Available Now</div>
            </div>
            <div className="tip-card tip-card--white tip-card--inactive">
              <div className="tip-card-header">
                <div className="tip-card-icon-container">
                  <svg
                    className="roadmap-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 232 144"
                  >
                    <rect
                      width="232"
                      height="144"
                      fill="var(--hover-color)"
                      fillOpacity="0.1"
                      rx="24"
                    ></rect>
                    <rect
                      width="80"
                      height="80"
                      x="28"
                      y="12"
                      fill="var(--med-faint-color)"
                      rx="10"
                    ></rect>
                    <rect
                      width="31"
                      height="12"
                      x="52"
                      y="100"
                      fill="var(--selected-font-color)"
                      rx="6"
                    ></rect>
                    <circle
                      cx="67.5"
                      cy="51.5"
                      r="17.5"
                      fill="var(--dark-font-color)"
                    ></circle>
                    <rect
                      width="80"
                      height="80"
                      x="123"
                      y="32"
                      fill="var(--med-faint-color)"
                      rx="10"
                    ></rect>
                    <rect
                      width="31"
                      height="12"
                      x="147"
                      y="120"
                      fill="var(--selected-font-color)"
                      rx="6"
                    ></rect>
                    <path
                      fill="var(--dark-font-color)"
                      d="M159.284 57.614c1.965-3.083 6.467-3.083 8.432 0l13.833 21.698c2.122 3.329-.269 7.688-4.216 7.688h-27.666c-3.947 0-6.338-4.36-4.216-7.688l13.833-21.698z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="tip-card-content">
                <div className="tip-card-title">Object Detection</div>
                <div className="tip-card-text">
                  Gain insight on specific objects in an image.
                </div>
              </div>
              <div className="tip-card-availability">Coming Soon</div>
            </div>
            <div className="tip-card tip-card--white tip-card--inactive">
              <div className="tip-card-header">
                <div className="tip-card-icon-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="roadmap-icon"
                    fill="none"
                    viewBox="0 0 232 144"
                  >
                    <rect
                      width="232"
                      height="144"
                      fill="var(--hover-color)"
                      fillOpacity="0.1"
                      rx="24"
                    ></rect>
                    <rect
                      width="80"
                      height="100"
                      x="28"
                      y="22"
                      fill="var(--med-faint-color)"
                      rx="10"
                    ></rect>
                    <rect
                      width="31"
                      height="12"
                      x="37"
                      y="102"
                      fill="var(--selected-font-color)"
                      rx="6"
                    ></rect>
                    <circle
                      cx="67.5"
                      cy="64.5"
                      r="16.5"
                      fill="var(--dark-font-color)"
                    ></circle>
                    <rect
                      width="80"
                      height="80"
                      x="123"
                      y="22"
                      fill="var(--med-faint-color)"
                      rx="10"
                    ></rect>
                    <rect
                      width="43"
                      height="12"
                      x="132"
                      y="30"
                      fill="var(--selected-font-color)"
                      rx="6"
                    ></rect>
                    <rect
                      width="60"
                      height="12"
                      x="132"
                      y="50"
                      fill="var(--selected-font-color)"
                      rx="6"
                    ></rect>
                    <rect
                      width="34"
                      height="12"
                      x="132"
                      y="69"
                      fill="var(--selected-font-color)"
                      rx="6"
                    ></rect>
                  </svg>
                </div>
              </div>
              <div className="tip-card-content">
                <div className="tip-card-title">Insight</div>
                <div className="tip-card-text">
                  Get house flipping tips for a specific image.
                </div>
              </div>
              <div className="tip-card-availability">Coming Soon</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Comp;
