import React, { useState, useEffect, useRef } from "react";

import "./style.css";

//compoents
import Spinner from "../Spinner";

//images
import LandingImg from "../../media/images/landing.jpg";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Comp = (props) => {
  const [animate, setAnimate] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const ref = useRef(null);

  const handleScroll1 = () => {
    const top = ref.current.getBoundingClientRect().top;
    console.log("page height: " + windowDimensions.height);
    console.log("top: " + top);
    console.log(top < 0.6 * windowDimensions.height);

    setAnimate(top < 0.6 * windowDimensions.height);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll1, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll1);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="section-image-container" ref={ref}>
      <div className="graphic-wrap">
        <div className={`test-container ${animate ? "animate-graphic" : ""}`}>
          <div className="graphic-img-wrap">
            <img src={LandingImg} alt="Flipit example image" />
          </div>
          <div className="code-wrapper">
            <div className="code-container">
              <div className="code-top-wrapper">
                <div className="flex-just-out">
                  <span className="code-sec-title">Request</span>
                  <span className="code-sec-title">Javascript</span>
                </div>
                <ol>
                  <li>
                    <span style={{ color: "#8095ff" }}>const</span> flipit =
                    {"  "}
                    <span style={{ color: "#00d4ff" }}>require</span>(
                    <span style={{ color: "#ffa956" }}>'flipit'</span>)(
                    <span style={{ color: "#ffa956" }}>'API_KEY'</span>)
                  </li>
                  <li className="mobie-li-only"></li>
                  <li>
                    <span style={{ color: "#8095ff" }}>const</span> data ={"  "}
                    <span style={{ color: "#8095ff" }}>await</span> flipit.
                    <span style={{ color: "#00d4ff" }}>analyze</span>
                    {`({`}
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }}>
                      images: [
                      <span style={{ color: "#ffa956" }}>'IMG_URL'</span>
                      ],
                    </span>
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }}>
                      analysis: <span style={{ color: "#ffa956" }}>'All'</span>
                    </span>
                  </li>
                  <li>{`});`}</li>
                </ol>
              </div>
              <div className="code-div-lin" />
              <div className="code-bottom-wrapper">
                <div className="flex-just-out flex-align-cent">
                  <span className="code-sec-title">Response</span>
                  <span className="code-sec-title ">
                    <Spinner size="20px" />
                    <span className="flex-align-cent respnse-span">
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                        />
                      </svg>
                      complete
                    </span>
                  </span>
                </div>

                <ol>
                  <li>data = {`{`}</li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    pov: <span style={{ color: "#ffa956" }}>"interior"</span>,
                  </li>

                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    finished: <span style={{ color: "#8095ff" }}>true</span>,
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    furnished: {`{`}
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    interiro_design:{" "}
                    <span style={{ color: "#ffa956" }}>"Contemporary"</span>,
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    use_case:{" "}
                    <span style={{ color: "#ffa956" }}>"livingroom"</span>,
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    {`}`},
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    floor_plan: <span style={{ color: "#ffa956" }}>"open"</span>
                    ,
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} /> room_type: {`{`}
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    type:{" "}
                    <span style={{ color: "#ffa956" }}>"living room"</span>,
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} /> materials: {`{`}
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    flooring: <span style={{ color: "#ffa956" }}>"wood"</span>,
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} /> walls: {`{`}
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    color: <span style={{ color: "#ffa956" }}>"white"</span>,
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    material:{" "}
                    <span style={{ color: "#ffa956" }}>"unknown"</span>,
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} /> {`}`},
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} /> ceiling: "wood",
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} /> {`}`},
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} /> attributes: {`{`}
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} /> windows: {`{`}
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    count: <span style={{ color: "#ffa956" }}>2</span>,
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    style:{" "}
                    <span style={{ color: "#ffa956" }}>"picture windows"</span>,
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} /> {`}`},
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    fireplace: {`{`}
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    material: <span style={{ color: "#ffa956" }}>"stone"</span>,
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} /> {`}`},
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} />
                    objects: [<span style={{ color: "#ffa956" }}>
                      "couch"
                    </span>, <span style={{ color: "#ffa956" }}>"chair"</span>,
                    <span style={{ color: "#ffa956" }}>"rug"</span>,
                    <span style={{ color: "#ffa956" }}>"table"</span>],
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    <span style={{ paddingLeft: "16px" }} /> {`}`},
                  </li>
                  <li>
                    <span style={{ paddingLeft: "16px" }} />
                    {`}`},
                  </li>
                  <li>{`};`}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comp;
