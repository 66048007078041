import React, { useState } from "react";
import "./style.css";

import DropdownItem from "./DropdownItem";

const Landing = (props) => {
  const defaultContnet = props.content[0].title;
  const [activeContent, setActiveContent] = useState(defaultContnet);

  const currentIndex = props.content.findIndex(
    (x) => x.title === activeContent
  );

  return (
    <ul className="docs-dic-wrapper flex-align-top flex-col">
      {props.content.map((content) => (
        <DropdownItem
          key={`docs-content-${content.title}`}
          content={content}
          active={currentIndex !== -1 ? activeContent : defaultContnet}
          setContent={setActiveContent}
        />
      ))}
      <a
        href="https://www.reddit.com/r/Flipit_ai"
        target="_blank"
        className="doc-list-item"
      >
        <div className="doc-list-item-head flex-align-cent flex-just-out ">
          <div className="flex-align-cent flex-just-lft">
            <svg
              style={{ width: 32, height: 32, marginRight: "16px" }}
              viewBox="0 0 24 24"
            >
              <path
                fill="var(--selected-font-color)"
                d="M17 12V3a1 1 0 00-1-1H3a1 1 0 00-1 1v14l4-4h10a1 1 0 001-1m4-6h-2v9H6v2a1 1 0 001 1h11l4 4V7a1 1 0 00-1-1z"
              ></path>
            </svg>
            <h5>Ask a question in the Flipit community</h5>
          </div>
          <svg
            style={{ width: 32, height: 32, transform: "rotate(-90deg)" }}
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--dark-font-color)"
              d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6-6-6 1.41-1.42z"
            ></path>
          </svg>
        </div>
      </a>
    </ul>
  );
};

export default Landing;
