import firebase from "firebase/app";
import "firebase/auth";
//import "firebase/firestore";

// const firebaseConfig = {
//   // apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
//   apiKey: "AIzaSyDEH6c0_93ogLRAHQefzN46rClA8xOs_jA",
//   authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID
// };

const firebaseConfig = {
  apiKey: "AIzaSyA5U8J2WdTBdp9rR621PedG8NGQZ3850GU",
  authDomain: "flipit---phase-1.firebaseapp.com",
  databaseURL: "https://flipit---phase-1.firebaseio.com",
  projectId: "flipit---phase-1",
  storageBucket: "flipit---phase-1.appspot.com",
  messagingSenderId: "690174609061",
  appId: "1:690174609061:web:22d9f9029fd8f7898def4c",
  measurementId: "G-WGF8MMKBXY",
};

firebase.initializeApp(firebaseConfig);
//firebase.analytics();

export default firebase;

// apiKey: "AIzaSyDEH6c0_93ogLRAHQefzN46rClA8xOs_jA",
// authDomain: "flipit-238117.firebaseapp.com",
// databaseURL: "https://flipit-238117.firebaseio.com",
// projectId: "flipit-238117",
// storageBucket: "flipit-238117.appspot.com",
// messagingSenderId: "545291452216",
// appId: "1:545291452216:web:1c34acb3a239c722",
