import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

//media
import Logo from "../../media/svgs/Logo";

//components
import Button from "../../comps/Button";

const Footer = (props) => {
  const year = new Date().getFullYear();
  const user = props.user;

  return (
    <div className="footer-wrap flex-just-cent flex-align-cent flex-col">
      {!user ? (
        <section className="section final-call section--center">
          <h2 className="section-heading">
            Go <span style={{ color: " var(--selected-font-color)" }}>Pro</span>{" "}
            Today
          </h2>
          <Link
            to={{
              pathname: "/modal/authentication",
              state: { modal: true },
            }}
          >
            <button className="large-download-button">Sign up</button>
          </Link>
        </section>
      ) : (
        <div style={{ marginBottom: "48px" }} />
      )}
      <div className="footer-links-wrap global-content-wrapper ">
        <div className="footer-links-column footer-links-column-legal flex-col">
          <Link to="/">
            <Logo height={24} />
          </Link>
          <p>A prodcut by Flipit.</p>
          <p>All rights reserved.</p>
          <p>© Flipit, inc {year}</p>
        </div>
        <div className="footer-links-column footer-links-column-list flex-col">
          <p>
            <b>About</b>
          </p>
          <Link
            to={{
              pathname: "/modal/authentication",
              state: { modal: true },
            }}
          >
            Sign up
          </Link>
          <Link
            to={{
              pathname: "/modal/authentication",
              state: { modal: true },
            }}
          >
            Sign in
          </Link>

          <Link to="/">Overview</Link>
          <Link to="/Pricing">Pricing</Link>
          <Link to="/Docs">Docs</Link>
        </div>
        <div className="footer-links-column footer-links-column-list  flex-col">
          <p>
            <b>General</b>
          </p>
          <a href="#">Contact</a>
          <a href="#">License</a>
          <a href="/api">Notice</a>
          <a href="/api">Press Inqueries</a>
          <a href="/Docs">Privacy & Cookies</a>
        </div>
        <div className="social-col">
          <a href="https://www.reddit.com/r/Flipit_ai" target="_blank">
            <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
              <path d="M17 12V3a1 1 0 00-1-1H3a1 1 0 00-1 1v14l4-4h10a1 1 0 001-1m4-6h-2v9H6v2a1 1 0 001 1h11l4 4V7a1 1 0 00-1-1z"></path>
            </svg>
          </a>
          <a href="https://twitter.com/flipitrealest8" target="_blank">
            <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
              <path d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 01-1.93.07 4.28 4.28 0 004 2.98 8.521 8.521 0 01-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z"></path>
            </svg>
          </a>
          <a href="#" target="_blank">
            <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
              <path d="M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z"></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
