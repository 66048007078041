import React, { useState, useEffect } from "react";
import "./style.css";

const Landing = (props) => {
  const [distanceFromTop, setDistanceFromTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setDistanceFromTop(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return distanceFromTop > 54 ? null : (
    <div className="scroll-arrow">
      <svg
        width="58px"
        height="24px"
        viewBox="0 0 58 24"
        version="1.1"
        onClick={(e) => window.scrollTo({ top: 96, behavior: "smooth" })}
      >
        <title>See More</title>
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity="0.300000012"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-771.000000, -986.000000)"
            stroke="var(--dark-font-color)"
            strokeWidth="3"
          >
            <g transform="translate(771.000000, 986.000000)">
              <polyline id="download-arrow" points="8 8 29 16 50 8"></polyline>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Landing;
