import React from "react";
import "./style.css";

//sections
import SideNavContent from "../../sections/SideNavContent";
import Header from "../../sections/CenterHeader";

//components
import DropdownList from "../../comps/DropdownList";

//content
const sidenavLinks = [
  { title: "Community", link: "https://www.reddit.com/r/Flipit_ai" },
  { title: "Examples", link: "" },
  { title: "Contact us", link: "" },
];
const pageContent = [
  {
    section: "Account",
    comp: DropdownList,
    content: [
      {
        title: "I forgot my password",
        content: (
          <div>
            <p>
              You can change your password for security reasons or reset it if
              you forget it. Your Flipit Account password is used to access many
              Flipit products, like the Flipit API and Flipit Console. Resetting
              your password is easy.
            </p>
            <ol>
              <li>
                Open the login popup by clicking the Sign-Up button in the upper
                right-hand corner.
              </li>
              <li>Navigate to the Sign in tab.</li>
              <li>Choose Forgot. In the bottom left-hand corner.</li>
              <li>
                Enter your email address and a password reset link will be sent
                to you.
              </li>
            </ol>
            <br />

            <p>
              If you are still unable to reset your password, or do not remember
              the email address associated with the account, please reach out to
              us through our{" "}
              <a href="https://www.reddit.com/r/Flipit_ai" target="blank">
                community
              </a>
              . A Flipit support team member will be happy to help!
            </p>
          </div>
        ),
      },
      {
        title: "Change my account email",
        content: (
          <div>
            <p>
              Flipit uses a pay-as-you-go pricing scheme. This means that at the
              end of each month your requests are totaled and your payment
              method on file is billed accordingly. In instanced of failed
              payment or lack of funds, Flipit will automatically cut off the
              corresponding API key’s access to the service.
            </p>
            <p>
              To regain access to the service, simply delete the old API key and
              generate a new one. You will be prompted to update your billing
              information and payment method before doing so.
            </p>
          </div>
        ),
      },
      {
        title: "What is machine learning?",
        content:
          "Machine learning is software that learns to perform a task from a collection of examples rather than through a person explicitly defining rules and formulas. This learning software is called a model. Teaching a model through examples is called training. Learn more in this beginner crash course.",
      },
    ],
  },
  {
    section: "Billing",
    comp: DropdownList,
    content: [
      {
        title: "My API key is no longer working.",
        content: (
          <div>
            <p>
              Flipit uses a pay-as-you-go pricing scheme. This means that at the
              end of each month your requests are totaled and your payment
              method on file is billed accordingly. In instanced of failed
              payment or lack of funds, Flipit will automatically cut off the
              corresponding API key’s access to the service.
            </p>
            <p>
              To regain access to the service, simply delete the old API key and
              generate a new one. You will be prompted to update your billing
              information and payment method before doing so.
            </p>
          </div>
        ),
      },
      {
        title: "Change my subscription level.",
        content: (
          <div>
            <p>
              Flipit uses a pay-as-you-go pricing scheme. This means that at the
              end of each month your requests are totaled and your payment
              method on file is billed accordingly. In instanced of failed
              payment or lack of funds, Flipit will automatically cut off the
              corresponding API key’s access to the service.
            </p>
            <p>
              To regain access to the service, simply delete the old API key and
              generate a new one. You will be prompted to update your billing
              information and payment method before doing so.
            </p>
          </div>
        ),
      },
      {
        title: "Update my payment method.",
        content: (
          <div>
            <p>
              Flipit uses a pay-as-you-go pricing scheme. This means that at the
              end of each month your requests are totaled and your payment
              method on file is billed accordingly. In instanced of failed
              payment or lack of funds, Flipit will automatically cut off the
              corresponding API key’s access to the service.
            </p>
            <p>
              To regain access to the service, simply delete the old API key and
              generate a new one. You will be prompted to update your billing
              information and payment method before doing so.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    section: "Legal",
    comp: DropdownList,
    content: [
      {
        title: "License",
        content: "filler Text",
      },
      {
        title: "Terms of Use",
        content: "filler Text",
      },
      {
        title: "Privacy & Cookies",
        content: "filler Text",
      },
    ],
  },
  {
    section: "Press Inqueries",
    comp: DropdownList,
    content: [
      {
        title: "Can I improve the modlel?",
        content: "filler Text",
      },
    ],
  },
];

const Docs = (props) => {
  return (
    <div>
      <Header
        title={
          <span>
            Flipit{" "}
            <span style={{ color: "var(--selected-font-color)" }}>Help</span>
          </span>
        }
        subTitle="Need a hand? Let's see if we can answer your question."
      />
      <SideNavContent
        root={"help"}
        staticLinks={sidenavLinks}
        pageContent={pageContent}
      />
    </div>
  );
};

export default Docs;
