import React, { useEffect, useState, useRef } from "react";
import "./style.css";

import axios from "axios";

//components
import MediaAnimation from "./MediaAnimation";
import Spinner from "../../../comps/Spinner";

const Landing = (props) => {
  const [index, setIndex] = useState(0);
  const [animation, setAnimation] = useState("pulse-a");
  const [analyzedImage, setAnalyzedImage] = useState("");
  const [analyzedImageLabel, setAnalyzedImageLabel] = useState("");

  //Refs
  const cameraInput = useRef(null);
  const fileInput = useRef(null);

  // useEffect(() => {
  //   if (cameraInput && cameraInput.current) {
  //     cameraInput.current.addEventListener("change", uploadFile, false);
  //   }
  //   return () => {
  //     if (cameraInput && cameraInput.current) {
  //       cameraInput.current.removeEventListener("change", uploadFile, false);
  //     }
  //   };
  // });

  const handleImagechange = (e) => {
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("image", image, image.name);
    uploadImage(formData);
  };

  const uploadImage = (formData) => {
    axios
      .post("/uploadImage", formData)
      .then((res) => {
        console.log(res.data.imageUrl);
        setAnalyzedImage(res.data.imageUrl);
        return axios.post("/getRoomType", res.data.imageUrl);
      })
      .then((res) => {
        setAnalyzedImageLabel(res.data.type);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.error);
      });
  };

  return (
    <form className="landing-upload-wrap">
      {analyzedImage ? (
        <div className="landing-uploaded-img-wrap">
          <div className="upload-lable">
            {!analyzedImageLabel ? <Spinner size="24px" /> : analyzedImageLabel}
          </div>
          <img src={analyzedImage} />
        </div>
      ) : (
        <div className="landing-upload-wrap-border flex-align-cent flex-just-cent">
          <MediaAnimation />
          <div style={{ marginBottom: "8px" }}>
            Drop an images here,{" "}
            <span
              onClick={(e) => fileInput.current.click()}
              className="browse-button"
            >
              browse
            </span>
            ,
            <br />
            or use your{" "}
            <span
              onClick={(e) => cameraInput.current.click()}
              className="browse-button"
            >
              camera
            </span>
          </div>
          <div
            style={{
              fontSize: "12px",
              fontWeight: 300,
              color: "var(--disabled-font-color)",
            }}
          >
            Supports: JPG, JPG2000, PNG
          </div>

          <input
            className="hidden-input"
            ref={fileInput}
            type="file"
            accept="image"
            accept="image/png, image/jpeg, image/jpg"
            onChange={(e) => handleImagechange(e)}
          />
          <input
            className="hidden-input"
            ref={cameraInput}
            type="file"
            accept="image/*;capture=camera"
          />
        </div>
      )}
    </form>
  );
};

export default Landing;

// onClick={(e) => cameraInput.current.click()}
