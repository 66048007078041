import React from "react";

//sections
import Card from "./Card";

//images
import Image1 from "../../media/images/1.jpg";
import Image2 from "../../media/images/2.jpg";
import Image3 from "../../media/images/3.jpg";
import Image4 from "../../media/images/4.jpg";
import Image5 from "../../media/images/5.jpg";
import Image6 from "../../media/images/6.jpg";
import Image7 from "../../media/images/7.jpg";
import Image8 from "../../media/images/8.jpg";
import Image9 from "../../media/images/9.jpg";
import Image10 from "../../media/images/10.jpg";

const Account = (props) => {
  const cards = [
    {
      img: Image1,
      title: "Living Room",
      text: `Flipit uses the contextual clues, like furniture, decorations, and room layouts to discern a room’s “type.”`,
    },
    {
      img: Image2,
      title: "Front Yard",
      text:
        "In addition to interior imagery, Flipit can analyze and extract relevant data from exterior shots as well.",
    },
    {
      img: Image5,
      title: "Bedroom",
      text:
        "Flipit can pick out data relevant to a particular room. For example, FlipIt will return estimated bed size for furnished bedrooms.",
    },
    {
      img: Image6,
      title: "Kitchen",
      text:
        "Flipit is able to pick out key attributes of any scene including materials for countertops, backsplashes, and cabinetry.",
    },
    {
      img: Image3,
      title: "Scandinavian",
      text:
        "In addition to physical attributes, Flipit will do its best to estimate a room’s interior design style.",
    },
    {
      img: Image4,
      title: "Family Room",
      text:
        "Flipit will use information gathered from other images in a batch operation to help it identify specific rooms.",
    },

    {
      img: Image7,
      title: "Pool",
      text:
        "Flipit can gather complex information like architectural style, pool size and yard type from seemingly plane imagery.",
    },
    {
      img: Image8,
      title: "Office",
      text:
        "Flipit can identify more than just what a room is being used for. For example, an office with a closet can be a potential bedroom.",
    },
    {
      img: Image9,
      title: "Porch",
      text:
        "Flipit has been trained on millions of images so difficult scenes like a closed in porch do not trip up the model.",
    },
    {
      img: Image10,
      title: "Master",
      text:
        "In addition to identifying room types, Flipit can further categorize rooms into subcategories (Master bedrooms, half baths…)",
    },
  ];
  return (
    <div className="examples">
      {cards.map((data) => (
        <Card data={data} />
      ))}
    </div>
  );
};

export default Account;
