import React from "react";
import "./style.css";

//components
import Cards from "../../comps/Cards";

const Landing = (props) => {
  let cards = [
    {
      price: "0",
      title: "Free",
      text: "Perfect for getting started. Capped at 1 unit.",
      button: "Start now",
    },
    {
      selected: true,
      price: "1.20",
      title: "Standard",
      text: "Only pay for what you use after the first free unit.",
      button: "Sign up",
    },
    {
      price: "Custom",
      title: "Enterprise",
      text: "Using a lot of data? Get in touch for a custom plan.",
      button: "Contact us",
    },
  ];

  return (
    <section className=" flex-align-cent flex-col">
      <div className="global-content-wrapper">
        <div className="header-text">
          <h1 className="title-cntainers">
            Pay for what
            <br /> you{" "}
            <span style={{ color: "var(--selected-font-color)" }}>use</span>
          </h1>
          <h2 className="text-cntainers">
            Start for free, then pay as you go. Flipit offers simple pricing
            that works for your app.
          </h2>
        </div>
        <Cards cards={cards} />
        <div className="pricing-card-key flex-align-cent flex-just-cent ">
          <h6 style={{ color: "var(--secondary-font-color)" }}>
            <b style={{ color: "var(--dark-font-color)" }}>1 Unit</b> = 1,000
            API calls
          </h6>
        </div>
      </div>
    </section>
  );
};

export default Landing;
