import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./style.css";

const Landing = (props) => {
  let location = useLocation().pathname.toLowerCase().replace(/\s/g, "-");
  let isNonSpecific = !props.index && location === `/${props.root}`;
  let route =
    "/" +
    props.root +
    "/" +
    props.section.section.toLowerCase().replace(/\s/g, "-");

  return (
    <NavLink
      className={isNonSpecific ? "docs-sidebar-item-selected" : ""}
      exact
      to={route}
      activeClassName="docs-sidebar-item-selected"
    >
      {props.section.section}
    </NavLink>
  );
};

export default Landing;
