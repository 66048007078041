import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./style.css";

//firebase
import firebase from "../../../firebase";

// components
import Button from "../../Button";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

const Auth = (props) => {
  const [signUp, setSignUp] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  //Authentication
  const history = useHistory();
  const user = props.user;
  if (user) history.push("/account");

  //Socia Auth
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const gitHubProvider = new firebase.auth.GithubAuthProvider();
  const socialSignIn = (provider) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        setErrorMessage("");
        history.push("/account");
      })
      .catch((error) => {
        console.log(error.code, ": ", error.message);
        setErrorMessage(error.message);
      });
  };

  return (
    <div className="flex-col" style={{ width: "100%", maxWidth: "320px" }}>
      <div className="auth-modal-header flex-align-cent flex-just-out">
        <h5
          className={`${signUp ? "auth-header-selection" : ""}`}
          onClick={() => setSignUp(true)}
        >
          Sign up
        </h5>
        <h5
          className={`${!signUp ? "auth-header-selection" : ""}`}
          onClick={() => setSignUp(false)}
        >
          Sign in
        </h5>
      </div>
      <div
        style={{ left: signUp ? 0 : "50%" }}
        className="auth-modal-selection-line"
      />

      <div style={{ padding: "16px 16px 0" }} className="flex-just-out">
        <div
          className="auth-modal-social-button"
          onClick={() => socialSignIn(googleProvider)}
        >
          <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M21.35 11.1h-9.17v2.73h6.51c-.33 3.81-3.5 5.44-6.5 5.44C8.36 19.27 5 16.25 5 12c0-4.1 3.2-7.27 7.2-7.27 3.09 0 4.9 1.97 4.9 1.97L19 4.72S16.56 2 12.1 2C6.42 2 2.03 6.8 2.03 12c0 5.05 4.13 10 10.22 10 5.35 0 9.25-3.67 9.25-9.09 0-1.15-.15-1.81-.15-1.81z"
            ></path>
          </svg>
          Google
        </div>

        <div
          className="auth-modal-social-button"
          onClick={() => socialSignIn(gitHubProvider)}
        >
          <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12 2A10 10 0 002 12c0 4.42 2.87 8.17 6.84 9.5.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34-.46-1.16-1.11-1.47-1.11-1.47-.91-.62.07-.6.07-.6 1 .07 1.53 1.03 1.53 1.03.87 1.52 2.34 1.07 2.91.83.09-.65.35-1.09.63-1.34-2.22-.25-4.55-1.11-4.55-4.92 0-1.11.38-2 1.03-2.71-.1-.25-.45-1.29.1-2.64 0 0 .84-.27 2.75 1.02.79-.22 1.65-.33 2.5-.33.85 0 1.71.11 2.5.33 1.91-1.29 2.75-1.02 2.75-1.02.55 1.35.2 2.39.1 2.64.65.71 1.03 1.6 1.03 2.71 0 3.82-2.34 4.66-4.57 4.91.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0012 2z"
            ></path>
          </svg>
          Github
        </div>
      </div>
      {errorMessage ? (
        <div
          className="auth-error-message"
          style={{ margin: "8px 16px -12px" }}
        >
          {errorMessage}
        </div>
      ) : null}
      <div style={{ padding: "16px" }}>{signUp ? <SignUp /> : <SignIn />}</div>
    </div>
  );
};

export default Auth;
