import React from "react";
import "./style.css";

import Card from "./Card";

const Auth = (props) => {
  let cardsArr = [];
  props.cards.forEach((card) =>
    cardsArr.push(
      <Card
        key={`${card.title}-card`}
        content={{
          selected: card.selected,
          image: card.image,
          price: card.price,
          title: card.title,
          text: card.text,
          button: card.button,
        }}
      />
    )
  );

  return (
    <div className="flex-col">
      {props.header ? (
        <div className="header-text">
          <h3>{props.header.title}</h3>
          <h4 className="text-cntainers">{props.header.subtitle}</h4>
        </div>
      ) : null}
      <div className="flex-align-cent flex-just-out cards-container">
        {cardsArr}
      </div>
    </div>
  );
};

export default Auth;
