import React, { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

//components
import Button from "../../comps/Button";
import Media from "./Media";
import Upload from "./Upload";
import ScrollHint from "./ScrollHint";

//meida
import T1 from "../../media/Landing/35.jpg";
import T2 from "../../media/Landing/36.jpg";
import T3 from "../../media/Landing/37.jpg";
import T4 from "../../media/Landing/38.jpg";
import T5 from "../../media/Landing/39.jpg";
import T6 from "../../media/Landing/40.jpg";

const Landing = (props) => {
  const [showUpload, setShowUpload] = useState(false);
  const [index, setIndex] = useState(0);
  const [animation, setAnimation] = useState("pulse-a");

  let imgList = [
    {
      text: "Room Type",
      room: "Living Room",
      img: T3,
      rotation: "-6deg",
    },
    {
      text: "Room Attributes",
      room: "Brick Backsplash",
      img: T2,
      rotation: "-3deg",
    },
    {
      text: "Flooring Material",
      room: "Hardwood Flooring",
      img: T4,
      rotation: "3deg",
    },
    {
      text: "Interior Design",
      room: "Modern Farmhouse",
      img: T1,
      rotation: "3deg",
    },
    {
      text: "Window Style",
      room: "Picture Window",
      img: T5,
      rotation: "3deg",
    },
    {
      text: "Point of View",
      room: "Exterior",
      img: T6,
      rotation: "6deg",
    },
  ];

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  useEffect(() => {
    const indexId = setInterval(() => {
      setIndex(index < imgList.length - 1 ? index + 1 : 0);
      setAnimation(animation == "pulse-a" ? "pulse-b" : "pulse-a");
    }, 5000);
    return () => clearInterval(indexId);
  });

  return (
    <section className="flex-align-cent flex-just-cent landing-wrap">
      <div className="landing-content">
        <div className="landing-text-wrap">
          <h1>
            Easily Identify
            <br />
            <span style={{ color: "var(--selected-font-color)" }}>
              {imgList[index].text}
            </span>
          </h1>
          <h2 style={{ marginBottom: "24px" }}>
            An API for extracting textual data from real estate imagery.
          </h2>
          <div className="flex-align-cent landing-button-wrap">
            <Link
              to={{
                pathname: "/modal/authentication",
                state: { modal: true },
              }}
            >
              <Button className="large-download-button">Try it</Button>
            </Link>
            <NavHashLink
              to="/#about-section"
              smooth
              scroll={(el) => scrollWithOffset(el)}
            >
              <Button className="large-download-button large-download-button--outline">
                learn more
              </Button>
            </NavHashLink>
          </div>
        </div>
        {showUpload ? (
          <Upload />
        ) : (
          <div className="landing-media-wrap">
            <div
              className="landing-media-container"
              style={{ transform: `rotate(${imgList[index].rotation})` }}
            >
              <div
                className="landing-media-card"
                style={{ animationName: animation }}
              >
                <div className="landing-media-card-tag flex-align-cent flex-just-cent">
                  {imgList[index].room}
                </div>
                <img src={imgList[index].img} />
              </div>
            </div>
          </div>
        )}
      </div>
      <ScrollHint />
    </section>
  );
};

export default Landing;
//onClick={(e) => setShowUpload(!showUpload)}
