import React from "react";
import { NavLink } from "react-router-dom";

import "./style.css";

const Navbar = (props) => {
  return (
    <nav className="navbar-wrap desktop-only">
      <NavLink to="/" exact activeClassName="active-navbar-link">
        Overview
      </NavLink>
      <NavLink to="/Examples" activeClassName="active-navbar-link">
        Examples
      </NavLink>
      <NavLink to="/Pricing" activeClassName="active-navbar-link">
        Pricing
      </NavLink>
      <NavLink to="/Docs" activeClassName="active-navbar-link">
        Docs
      </NavLink>
      <NavLink to="/Help" activeClassName="active-navbar-link">
        Help
      </NavLink>
    </nav>
  );
};

export default Navbar;
