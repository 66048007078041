import React from "react";
import "./style.css";

const Button = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={`button flex-align-cent flex-just-cent ${props.className}`}
    >
      {props.children}
    </button>
  );
};

export default Button;
