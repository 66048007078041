import React from "react";
import { useHistory } from "react-router-dom";
import "./style.css";

//sections
import SideNavContent from "../../sections/SideNavContent";

//components
import Overview from "./Overview";
import AccountSummary from "./AccountSummary";
import Billing from "./Billing";
import Log from "./Log";

//content
const sidenavLinks = [
  { title: "Support", link: "https://www.reddit.com/r/Flipit_ai" },
  { title: "Contact us", link: "" },
  { title: "Log out", link: "/logout" },
];
const pageContent = [
  {
    section: "Overview",
    comp: Overview,
    content: [
      {
        title: "Where do I start?",
        content: "filler Text",
      },
    ],
  },
  {
    section: "Account",
    comp: AccountSummary,
    content: [
      {
        title: "Where do I start?",
        content: "filler Text",
      },
    ],
  },
  {
    section: "Billing",
    comp: Billing,
    content: [
      {
        title: "Integration?",
        content: "filler Text",
      },
    ],
  },
  {
    section: "Log",
    comp: Log,
    content: [
      {
        title: "Integration?",
        content: "filler Text",
      },
    ],
  },
];

const Account = (props) => {
  //Authentication
  const history = useHistory();
  const user = props.user;
  if (!user) history.push("/authentication");

  return (
    <div>
      <SideNavContent
        user={user}
        root={"account"}
        pageContent={pageContent}
        staticLinks={sidenavLinks}
      />
    </div>
  );
};

export default Account;
