import React from "react";
import "./style.css";

const Landing = (props) => {
  const isActive = props.active == props.content.title;

  return (
    <li className={`doc-list-item ${isActive ? "doc-list-item-open" : ""}`}>
      <div
        className="doc-list-item-head flex-align-cent flex-just-out "
        onClick={() => props.setContent(props.content.title)}
      >
        <h5>{props.content.title}</h5>
        <svg style={{ width: 32, height: 32 }} viewBox="0 0 24 24">
          <path
            fill="var(--dark-font-color)"
            d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6-6-6 1.41-1.42z"
          ></path>
        </svg>
      </div>
      {isActive ? (
        <div className="doc-list-item-content">{props.content.content}</div>
      ) : null}
    </li>
  );
};

export default Landing;
