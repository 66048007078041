import React, { useState } from "react";

//sections
import Code from "../../../comps/Code";
import Spinner from "../../../comps/Spinner";

const Account = (props) => {
  const [active, setActive] = useState(false);

  return (
    <div
      id={`${props.data.title.replace(" ", "").toLowerCase()}`}
      className={`example ${active ? "example-acitve" : ""}`}
    >
      <div className="example-image">
        <img
          src={props.data.img}
          className
          alt={`Flipit ${props.data.title} image`}
          loading="lazy"
        />
      </div>

      <div className="example-content ">
        <button
          className={`example-button header-download-button  ${
            active ? "example-button-acitve" : ""
          }`}
          onClick={(e) => {
            setActive(!active);
          }}
        >
          {active ? (
            "Close"
          ) : (
            <div className="flex-just-cent flex-align-cent">
              Process Image
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                />
              </svg>
            </div>
          )}
        </button>
        <div className="flex-align-cent flex-just-lft">
          <h2 className="example-title">{props.data.title}</h2>

          {active ? (
            <div className="spinner-wrp">
              <Spinner size="24px" />
            </div>
          ) : (
            ""
          )}
        </div>
        <p className="example-text">{props.data.text}</p>
        <div className="example-code">
          <Code />
        </div>
      </div>
    </div>
  );
};

export default Account;
