import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

// components
import Button from "../../../comps/Button";

//media
import placeholder from "../../../media/images/placeholder.jpg";

const Auth = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const user = props.user;

  // create element ref
  const wrapperRef = useRef(null);
  const buttonRef = useRef(null);
  const userSetDarkMode = JSON.parse(localStorage.getItem("userSetDarkMode"));

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !buttonRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  return (
    <div className="flex-align-cent flex-just-cent auth-wrapper desktop-only">
      {!user ? (
        <div className="flex-align-cent flex-just-cent">
          <Link
            to={{
              pathname: "/modal/authentication",
              state: { modal: true },
            }}
          >
            <Button className="blue-button header-download-button ">
              Sing up
            </Button>
          </Link>

          <div
            className="head-svg-wrap"
            onClick={() => setIsVisible(!isVisible)}
            ref={buttonRef}
          >
            <svg viewBox="0 0 24 24" height="24px">
              <path
                fill="currentColor"
                d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      ) : (
        <img
          src={user.photoURL ? user.photoURL : placeholder}
          onClick={() => setIsVisible(!isVisible)}
          ref={buttonRef}
        />
      )}
      {isVisible ? (
        <ul
          className={`auth-dowpdown ${
            isVisible ? "auth-dowpdown-open" : ""
          } flex-col`}
          ref={wrapperRef}
        >
          {user ? <Link to="/account">Account</Link> : null}
          {user ? (
            <Link to="/logout">Log out</Link>
          ) : (
            <Link
              to={{
                pathname: "/modal/authentication",
                state: { modal: true },
              }}
            >
              Log in
            </Link>
          )}

          <div
            onClick={() => {
              if (!userSetDarkMode) {
                localStorage.setItem("userSetDarkMode", true);
                document.documentElement.setAttribute("data-theme", "dark");
              } else {
                localStorage.setItem("userSetDarkMode", false);
                document.documentElement.setAttribute("data-theme", null);
              }
            }}
            className={`dark-mode-toggle ${
              userSetDarkMode ? "dark-mode-toggle-active" : ""
            }`}
          >
            Dark Mode
          </div>
        </ul>
      ) : null}
    </div>
  );
};

export default Auth;
