import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

//compoents
import Logo from "../../media/svgs/Logo";
import Navbar from "../../comps/Navbar";
import MobileMenu from "../../comps/MobileMenu";
import Auth from "./Auth";

const Header = (props) => {
  const user = props.user;

  return (
    <div className="header-wrapper flex-align-cent flex-just-out">
      <Link to="/">
        <Logo />
      </Link>
      <Navbar />
      <Auth user={user} />
      <MobileMenu user={user} />
    </div>
  );
};

export default Header;
