import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//firebase
import firebase from "../../../../firebase";

// components
import Button from "../../../Button";
import Spinner from "../../../Spinner";

const Auth = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, SetUser] = useState({
    email: "",
    password: "",
    passwordCheck: "",
  });
  const history = useHistory();

  // console.log("signup user: ", user ? true : false);

  //input handlers
  const [emailValidation, SetEmailValidation] = useState(false);
  const [passwordStrength, SetPasswordStrength] = useState({});
  const [passwordFocus, SetPasswordFocus] = useState(false);
  const [passwordCheckFocus, SetPasswordCheckFocus] = useState(false);
  const emailValidaiton = (input) => {
    let emailCheck = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return input.match(emailCheck) ? true : false;
  };
  const passwordStrengthCheck = (input) => {
    let password = {};
    let med = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}/;
    let strong = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,}$/;

    let upperCase = input.match(/^(?=.*[A-Z])/);
    let numbers = input.match(/^(?=.*[0-9])/);
    let specialChar = input.match(/^(?=.*[!@#$&*])/);

    let message = !input
      ? undefined
      : input.match(strong)
      ? "Strong"
      : input.match(med)
      ? "Medium"
      : "Weak";

    password = {
      message: message,
      upperCase: upperCase,
      numbers: numbers,
      specialChar: specialChar,
    };
    SetPasswordStrength(password);
  };

  //submit form
  const signUpUser = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setLoading(true);

    user.password === user.passwordCheck
      ? firebase
          .auth()
          .createUserWithEmailAndPassword(user.email, user.password)
          .then(() => {
            setLoading(false);
            setErrorMessage("");
            history.push("/account");
          })
          .catch((error) => {
            console.log(error.code, ": ", error.message);
            handleErrorMessage(error.message);
          })
      : handleErrorMessage("Passwords do not match");
  };

  const handleErrorMessage = (message) => {
    setLoading(false);
    setErrorMessage(message);
  };

  return (
    <form className="signup-form flex-col" onSubmit={(e) => signUpUser(e)}>
      <input
        id="email"
        name="email"
        type="email"
        label="Email"
        placeholder="email"
        value={user.email}
        onChange={(e) => {
          SetEmailValidation(emailValidaiton(e.target.value));
          SetUser({
            email: e.target.value,
            password: user.password,
            passwordCheck: user.passwordCheck,
          });
        }}
        style={{
          border: `1px solid ${
            !emailValidation ? "rgba(255, 255, 255, 0)" : "#02bfa6"
          }`,
        }}
      />
      <div
        className={`auth-password-strength-hint ${
          passwordFocus ? "auth-password-strength-hint-active" : ""
        }`}
      >
        <p>
          Strength:{" "}
          <span
            style={{
              color:
                passwordStrength.message === "Strong"
                  ? "#02bfa6"
                  : passwordStrength.message === "Medium"
                  ? "rgb(255, 203, 43)"
                  : "rgb(249, 41, 83)",
            }}
          >
            {passwordStrength.message}
          </span>
        </p>{" "}
        Hint: Use{" "}
        <span style={{ color: passwordStrength.upperCase ? "#02bfa6" : "" }}>
          capitals
        </span>
        ,{" "}
        <span style={{ color: passwordStrength.numbers ? "#02bfa6" : "" }}>
          numbers
        </span>
        , and{" "}
        <span style={{ color: passwordStrength.specialChar ? "#02bfa6" : "" }}>
          special characters
        </span>
      </div>
      <input
        id="password-signup"
        name="password"
        type="password"
        label="Password"
        placeholder="Password"
        value={user.password}
        onChange={(e) => {
          passwordStrengthCheck(e.target.value);
          SetUser({
            email: user.email,
            password: e.target.value,
            passwordCheck: user.passwordCheck,
          });
        }}
        onFocus={() => SetPasswordFocus(true)}
        onBlur={() => SetPasswordFocus(false)}
        style={{
          border:
            passwordStrength.message === undefined
              ? "none"
              : `1px solid ${
                  passwordStrength.message === "Strong"
                    ? "#02bfa6"
                    : passwordStrength.message === "Medium"
                    ? "rgb(255, 203, 43)"
                    : "rgb(249, 41, 83)"
                }`,
        }}
      />
      <div
        className="auth-password-check-note"
        style={{ position: "relative", width: "100%" }}
      >
        <div
          style={{
            display:
              user.passwordCheck &&
              passwordCheckFocus &&
              user.passwordCheck == user.password
                ? "flex"
                : "none",
          }}
        >
          Doesn't Match
        </div>
        <input
          id="passwordCheck"
          name="passwordCheck"
          type="password"
          label="Confirm Password"
          placeholder="Re-enter password"
          value={user.passwordCheck}
          onChange={(e) =>
            SetUser({
              email: user.email,
              password: user.password,
              passwordCheck: e.target.value,
            })
          }
          onFocus={() => SetPasswordCheckFocus(true)}
          onBlur={() => SetPasswordCheckFocus(false)}
          style={{
            border:
              user.passwordCheck === ""
                ? "none"
                : `1px solid ${
                    user.passwordCheck === user.password
                      ? "#02bfa6"
                      : "rgb(249, 41, 83)"
                  }`,
          }}
        />
      </div>
      <div style={{ fontSize: "10px", marginBottom: "12px" }}>
        By continuing, you are accepting our Terms of Service and Privacy
        Policy.
      </div>
      {errorMessage ? (
        <div className="auth-error-message">{errorMessage}</div>
      ) : null}
      <div
        style={{ margin: "-12px 0" }}
        className="auth-modal-submit-wrapper flex-just-out"
      >
        <div></div>
        <Button className="blue-button">
          {!loading ? (
            "Submit"
          ) : (
            <div className="flex-align-cent">
              <Spinner button={true} size={"24px"} />
              <span style={{ marginLeft: "12px" }}>Loading</span>
            </div>
          )}
        </Button>
      </div>
    </form>
  );
};

export default Auth;
