import React from "react";
import "./style.css";

import Placeholder from "../../../../media/svgs/Image-Placeholder";

const Landing = (props) => {
  return (
    <div className="Media-animation-wrap">
      <Placeholder />
      <Placeholder />
      <Placeholder />
    </div>
  );
};

export default Landing;
